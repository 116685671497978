import { useRef, useEffect, useState } from "react";
import { Button } from "./button";
import ReCAPTCHA from "react-google-recaptcha";
import sendMail from "./Mailtable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CAPTCHA_KEY, formEmailPayload } from "../config";
import { Alert } from "./alert";
import { useAnalytics } from "../useAnalytics";

interface Resource {
  formTitle: string;
  formSubTitle: string;
  resources?: ResourceField[];
}

interface ResourceField {
  key: string;
  value: string;
  required: boolean;
  inpValue?: string;
}

interface Props {
  value: Resource;
}

interface ValidationResult {
  isValid: boolean;
  errorMsg: string;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Validation = (field: ResourceField, value: string): ValidationResult => {
  if (!value.trim() && field?.key !=="newsletter.captcha") {
    return { isValid: false, errorMsg: "This field is required" };
  }

  if (field.value === "Email Address" && !emailRegex.test(value)) {
    return { isValid: false, errorMsg: "Please enter a valid email address" };
  }

  return { isValid: true, errorMsg: "" };
};

const NewsletterForm = ({ value }: Props) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [formValue, setformValue] = useState<ResourceField[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captcharERR, setCaptcharERR] = useState(null);
  const {signUpForNL} =  useAnalytics();
  useEffect(() => {
    if (value.resources) {
      setformValue(value.resources);
    }
  }, [value]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldValue: string
  ) => {
    const newValue = e.target.value;
    const updatedformValue = formValue.map((item) => {
      if (item.value === fieldValue) {
        const { isValid, errorMsg } = Validation(item, newValue);
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldValue]: isValid ? "" : errorMsg,
        }));
        return { ...item, inpValue: newValue };
      }
      return item;
    });
    setformValue(updatedformValue);
  };
  const handleCaptcha = (token: any) => {
    console.log("tokenn,", token);
    setCaptchaToken(token);
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: Record<string, string> = {};
    let isValid = true;
    formValue.forEach((item) => {
      if (
        !item.key.includes("help") &&
        !item.key.includes("submit") &&
        !item.key.includes("option") &&
        !item.key.includes("message") &&
        !item.key.includes("empty")
      ) {
        const { isValid: fieldValid, errorMsg } = Validation(
          item,
          item.inpValue || ""
        );
        if (!fieldValid) {
          isValid = false;
          newErrors[item.value] = errorMsg;
        }
      }
    });
    if (!captchaToken) {

      newErrors["captcha"] = "Error: CAPTCHA verification failed. Please try again.";
    } else {
      const captchaField: ResourceField = {
        key: "captchaToken",

        value: "captcha",

        required: true, // Assuming it's required

        inpValue: captchaToken, // Optional, but can hold the token
      };

      // Push the captchaField into formValue

      formValue.push(captchaField);
    }
    console.log("formvalues" + JSON.stringify(formValue));
    setErrors(newErrors);
    let payload = formEmailPayload(formValue);
    if (isValid) {
      try {
       let response = await sendMail(formValue);
       if( response?.msg=="Error: CAPTCHA verification failed. Please try again."){
        setCaptcharERR(response?.msg);
        signUpForNL(payload,response?.msg);
       }else{
        setformValue(value.resources || []); // Reset form data if needed
        setCaptcharERR(null);
        signUpForNL(payload,"Success");
       }
       window.scrollTo({
         top: 0,
         behavior: 'smooth' // Optional: for smooth scrolling
       });
      } catch (error) {
        console.error("Error sending email:", error);
        signUpForNL(payload,error);
      }
    }
  };
  return (
    <div className="flex flex-col justify-start -ml-8 sm:mx-32">
      <div>
        
        {
                      captcharERR &&
                        <Alert captchaErr={captcharERR}/>                     }
        <h1 className="newsletterheader">{value?.formTitle}</h1>
        <p className="newsletterparagraph">{value?.formSubTitle}</p>
        <br />
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            
            {formValue.map((item) => {
              if (
                !item.key.includes("help") &&
                !item.key.includes("submit") &&
                !item.key.includes("captcha") &&
                !item.key.includes("option") &&
                !item.key.includes("message") &&
                !item.key.includes("empty")
              ) {
                return (
                  <div key={item.value} className="input_block">
                    <label className="block mb-8 mt-3 text-base font-bold text-gray-900">
                      <p className="text-label mb-2">{item?.value}</p>
                      <input
                        type="text"
                        value={item.inpValue || ""}
                        onChange={(e) => handleChange(e, item.value)}
                        onBlur={(e) => handleChange(e, item.value)}
                        className={`focus:ring-blue-500 newsletter-shadow mb-2
                                    focus:border-blueBorder bg-gray-light border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                      errors[item.value]
                                        ? "border-errField bg-errInput"
                                        : "border-gray-300"
                                    }`}
                        required
                        data-testid={item.value}
                      />
                      
                    {errors[item.value] && (
                      <span className="text-errField mx-6 inline-block" style={{fontWeight:"normal"}}>
                        {errors[item.value]}
                      </span>
                    )}
                    </label>
                  </div>
                );
              } else if (
                item.key.includes("captcha") &&
                !item.key.includes("help") &&
                !item.key.includes("empty")  &&
                !item.key.includes("captchaToken")
              ) {
                return (
                  <div key="captcha" className="my-4 sm:mx-6 mx-8">
                    <ReCAPTCHA
                      ref={captchaRef}
                      //sitekey="6Ld4HFMqAAAAANf9ZeyaVN6G5VJbPkEqG_-dyns0"
                      sitekey={CAPTCHA_KEY}
                      theme="light"
                      datatype="image"
                      onChange={handleCaptcha}
                    />                    
                  </div>
                );
              }
              return null;
            })}
            <div className="newsletterbutton">
              <Button text="Submit" onClickButton={handleSubmit} />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export { NewsletterForm };
